<template>
  <v-container id="Dashboard" fluid tag="section" class="px-8" data-test-page-name="Dashboard">
    <!-- TODO: This should be abstracted into a dashboard alert component -->
    <v-row v-if="expiringSoonPaymentRequest">
      <v-col cols="12">
        <v-alert :type="expiredPaymentRequest ? 'error' : 'warning'" data-test="payment-alert">
          <!-- TODO: shouldn't be hard-coded -->
          Uh oh! Your free trial access
          {{ unpaidPaymentRequest.course ? `to ${unpaidPaymentRequest.course.name}` : '' }}
          {{
            expiredPaymentRequest
              ? 'has expired'
              : 'will soon expire on ' + unpaidDueDate.format('ddd, MMM DD')
          }}!
          <template v-if="expiredPaymentRequest">
            You might be missing out on important assignments.
          </template>
          You can renew your subscription by going to the billing page.
          <template #append>
            <v-btn href="/billing" text> Go To Billing</v-btn>
          </template>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="hasSessionId">
      <v-col cols="12">
        <v-alert type="success" dismissible>
          Your payment was successful. If you cannot see your course, please contact support at
          <a href="mailto:support@stemble.com">support@stemble.com</a>.
        </v-alert>
      </v-col>
    </v-row>
    <h1>Dashboard</h1>
    <v-row justify="space-around">
      <v-col cols="12" lg="6" class="fill-height">
        <v-row no-gutters class="mt-auto">
          <h2 class="display-1 font-weight-bold text-uppercase mb-4">
            {{ $t('announcement') }}
          </h2>
          <!-- TODO: re-enable once announcemnets are ready -->
          <v-btn
            v-show="false"
            class="no-background-hover ml-5"
            width="20px"
            height="20px"
            color="secondary"
            elevation="1"
            fab
            to="announcement"
          >
            <v-icon> mdi-plus</v-icon>
          </v-btn>
        </v-row>
        <announcement-card
          :title="announcement.title"
          :text="announcement.text"
          :course-name="
            unpaidPaymentRequest && unpaidPaymentRequest.course
              ? unpaidPaymentRequest.course.name
              : null
          "
          :expiry-date="unpaidPaymentRequest ? unpaidDueDate.format('ddd, MMM DD') : ''"
        />
      </v-col>
      <v-col cols="12" lg="6" class="dashboard_card_height fill-height">
        <v-row no-gutters class="mt-auto">
          <h2 class="display-1 font-weight-bold text-uppercase mb-4">
            {{ $t('timeline') }}
          </h2>
          <v-spacer />
        </v-row>
        <v-col v-if="formattedTimelineItems.length > 0" class="dashboard_card_height">
          <v-timeline dense class="mt-n7">
            <v-timeline-item v-for="(item, i) in formattedTimelineItems" :key="i" small hide-dot>
              <v-row no-gutters class="rounded pt-5 pl-6 ma-n2 background-row-color">
                <v-col cols="2">
                  <v-tooltip left>
                    <template v-slot:activator="{on, attrs}">
                      <v-btn
                        fab
                        :aria-label="$t('dashboardPage.ariaLabels.timeline')"
                        small
                        :color="item.color"
                        :to="item.to ? item.to : null"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon width color="white">
                          {{ item.icon }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('dashboardPage.tooltips.timeline') }}</span>
                  </v-tooltip>
                </v-col>

                <v-col cols="10" class="pl-2">
                  <p class="font-weight-light py-0 my-0" style="font-size: 12px">
                    {{ item.date }}
                  </p>
                  <p class="font-weight-bold text-truncate" style="font-size: 18px">
                    {{ item.text }}
                  </p>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-col>
        <v-card v-else-if="isReady" class="mt-0 elevation-0 dashboard_card_height">
          <v-col cols="12" md="12" lg="12">
            <placeholder-image
              img-url="/img/placeholder/stemble-no-event.png"
              width="263px"
              :label="$t('placeholderImageLabels.events')"
            />
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="$gate.isFaculty()" class="pt-2" no-gutters align="end">
      <div>
        <h2 class="display-1 font-weight-bold text-uppercase d-inline-block mb-4">
          {{ $t('assignments') }}
        </h2>
        <v-btn
          v-if="canCreateAssignments"
          small
          class="ml-5"
          color="secondary"
          elevation="0"
          rounded
          @click="dialogs.chooseAssignmentCourse = true"
        >
          <v-icon medium> mdi-plus</v-icon>
          {{ $t('assignmentsPage.addNewAssignment') }}
        </v-btn>
      </div>
    </v-row>
    <v-row v-if="!$gate.isFaculty()" align="baseline" class="pt-n3" no-gutters>
      <p class="display-1 font-weight-bold text-uppercase">
        {{ $t('assignment-overview') }}
      </p>
      <v-spacer />
      <!-- TODO: renable once my assignments is ready -->
      <v-btn
        v-if="false"
        large
        text
        class="mr-n6 no-background-hover"
        to="courses/course-101/assignments text-capitalize"
      >
        {{ $t('see-all-assignments') }}
      </v-btn>
    </v-row>
    <v-row>
      <template v-if="!isReady">
        <v-col cols="12" md="4" lg="4">
          <v-skeleton-loader class="mx-auto" min-width="100%" type="card" />
        </v-col>

        <v-col cols="12" md="4" lg="4">
          <v-skeleton-loader class="mx-auto" min-width="100%" type="card" />
        </v-col>

        <v-col cols="12" md="4" lg="4">
          <v-skeleton-loader class="mx-auto" min-width="100%" type="card" />
        </v-col>
      </template>
      <template v-else-if="isReady && assignments.length === 0">
        <v-col cols="12" md="4" offset-md="4" lg="4" offset-lg="4">
          <placeholder-image
            img-url="/img/placeholder/stemble-no-assignments.png"
            :label="$t('placeholderImageLabels.assignments')"
          />
        </v-col>
      </template>
      <template v-else-if="isReady && $gate.isFaculty()">
        <v-col
          v-for="assignment in assignments"
          :key="assignment.id"
          cols="12"
          md="6"
          lg="4"
          xl="4"
        >
          <faculty-assignment-card :assignment="assignment" />
        </v-col>
      </template>
      <template v-else-if="isReady && !$gate.isFaculty()">
        <v-col
          v-for="assignment in assignments"
          :key="assignment.id"
          cols="12"
          md="6"
          lg="4"
          xl="4"
        >
          <student-assignment-card :assignment="assignment" />
        </v-col>
      </template>
    </v-row>
    <v-dialog v-model="dialogs.chooseAssignmentCourse" width="400px">
      <v-card>
        <v-toolbar dark color="secondary" dense flat>
          <v-toolbar-title class="white--text"> New Assignment</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-select
            v-model="chosenCourseIdForNewAssignment"
            :items="coursesAuthorizedForCreateAssignment"
            item-text="name"
            item-value="id"
            outlined
            :label="$t('newAssignmentValidation.chooseACourse')"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="secondary darken-1"
              text
              @click="
                dialogs.chooseAssignmentCourse = false;
                chosenCourseIdForNewAssignment = null;
              "
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              :disabled="!chosenCourseIdForNewAssignment"
              color="secondary darken-1"
              class="font-weight-bold"
              text
              :href="`/courses/${chosenCourseIdForNewAssignment}/assignments/create`"
              @click="dialogs.chooseAssignmentCourse = false"
            >
              {{ $t('assignmentsPage.addNewAssignment') }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import moment from 'moment';
import {CourseLikeAssignment} from '@/assignments/models/CourseLikeAssignment';
import Assignment from '../../assignments/models/Assignment';
import AssignmentExtension from '@/assignments/models/AssignmentExtension';
import {mapGetters} from 'vuex';
import SectionEvent from '@vuex-orm/core/lib/model/Model';
import VueApexCharts from 'vue-apexcharts';
import PlaceholderImage from '@/common/components/PlaceholderImage.vue';
import {ASSIGNMENT_OVERVIEW} from '@/router/route-names';
import AnnouncementCard from '@/dashboard/components/AnnouncementCard';
import PaymentRequest from '@/payments/models/PaymentRequest';
import AssignmentGrade from '@/grades/models/AssignmentGrade';
import RadarChart from '@/dashboard/components/RadarChart';
import FacultyAssignmentCard from '@/assignments/components/FacultyAssignmentCard';
import StudentAssignmentCard from '@/assignments/components/StudentAssignmentCard';
import {Paginator} from '@/common/utils/pagination';
import {getCourseLikeAssignments} from '@/assignments/api/course-like-assignments';

export default {
  name: 'Dashboard',
  components: {
    AnnouncementCard,
    PlaceholderImage,
    apexchart: VueApexCharts,
    RadarChart,
    FacultyAssignmentCard,
    StudentAssignmentCard,
  },
  props: {
    courses: {
      type: Array,
      required: true,
    },
  },
  setup: () => {
    document.title = `Dashboard | Stemble`;
  },
  data() {
    return {
      chosenCourseIdForNewAssignment: null,
      dialogs: {
        chooseAssignmentCourse: false,
      },
      menu: false,
      sectionCard_Faculty: [],
      value: 0,
      itemsAssignment: [{title: 'Assignment 1'}, {title: 'Assignment 2'}, {title: 'Assignment 3'}],

      seriesRadarChart: null,
      optionsRadarChart: null,
      seriesPieChart: null,
      optionsPieChart: null,
    };
  },

  computed: {
    ...mapGetters({
      courseMaterialDashboard: 'course',
      assignmentDashboard: 'assignment',
      user: 'user',
    }),
    canCreateAssignments() {
      return this.$gate.can('create', 'Assignment');
    },
    formattedTimelineItems() {
      if (this.sortedTimeLineItems && this.sortedTimeLineItems.length > 0) {
        return this.sortedTimeLineItems.map((assignment) => {
          const dueDate = moment(assignment.courseLikeAssignments[0].dueDate);
          return {
            color: '#BE2C62',
            icon: 'mdi-calendar-month-outline',
            date: dueDate ? 'Due ' + dueDate.fromNow() : 'unknown',
            text: assignment.name ? assignment.name : 'unknown',
            to: {
              name: ASSIGNMENT_OVERVIEW,
              params: {
                courseId: assignment.courseId,
                assignmentId: assignment.id,
              },
            },
          };
        });
      }
      return [];
    },
    sortedTimeLineItems() {
      const newTimeLines = [...this.futureAssignments].slice(0, 4);
      if (newTimeLines.length > 0) {
        return newTimeLines.sort((a, b) => {
          const dueDateA = moment(a.courseLikeAssignments[0].dueDate).valueOf();
          const dueDateB = moment(b.courseLikeAssignments[0].dueDate).valueOf();
          return dueDateA - dueDateB;
        });
      }
      return [];
    },
    isReady() {
      return this.$auth.hasUserEverLoggedIn && this.areAssignmentsLoaded;
    },
    areAssignmentsLoaded() {
      return this.$loadingFlags.isLoaded(LoadingFlag.DashboardAssignments);
    },
    courseEvents() {
      let outputArray = [];
      //loop through all courses
      for (let i = 0; i < this.courses.length; i++) {
        outputArray.push(
          SectionEvent.query().where('ownerId', this.courses[i].sectionIds).with('location').get()
        );
      }
      //flatten the array
      return [].concat.apply([], outputArray);
    },
    numCourseEvents() {
      if (this.$loadingFlags.isLoading(LoadingFlag.DashboardEvents)) {
        return null;
      } else {
        return this.courseEvents.filter(this.isThisWeek).length;
      }
    },
    numCourseAssignments() {
      if (this.$loadingFlags.isLoading(LoadingFlag.DashboardAssignments)) {
        return null;
      } else {
        return this.assignments.length;
      }
    },
    assignments() {
      return Assignment.fullQuery({courseLikeAssignments: true})
        .all()
        .filter((assignment) => {
          if (!assignment.courseLikeAssignments.length) {
            return false;
          }
          const extensions = this.checkForExtensionsByAssignment(assignment);

          if (extensions.length) {
            const newDueDate = extensions[0].newDueDate;
            if (newDueDate) {
              assignment.extendedDueDate = newDueDate;
              return moment(newDueDate).isSameOrAfter(moment());
            }
          }

          // FIXME: this doesn't handle staff very well where they could have multiple due dates
          return moment(assignment.courseLikeAssignments[0].dueDate).isSameOrAfter(
            moment().subtract(7, 'days').toISOString()
          );
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    futureAssignments() {
      return Assignment.fullQuery({courseLikeAssignments: true})
        .all()
        .filter((assignment) => {
          if (!assignment.courseLikeAssignments.length) {
            return false;
          }
          return moment(assignment.courseLikeAssignments[0].dueDate).isSameOrAfter(moment());
        });
    },
    newAssignmentPageTo() {
      return {
        name: ASSIGNMENT_OVERVIEW,
        params: {
          courseId: this.chosenCourseIdForNewAssignment,
          assignmentId: 'new',
        },
      };
    },
    expiredPaymentRequest() {
      if (this.unpaidDueDate) {
        return moment(this.unpaidDueDate).isSameOrBefore(moment());
      }
      return false;
    },
    expiringSoonPaymentRequest() {
      if (this.unpaidSoonDueDate) {
        return moment(this.unpaidSoonDueDate).isSameOrBefore(moment());
      }
      return false;
    },
    unpaidPaymentRequest() {
      return PaymentRequest.fullQuery({
        course: true,
        hasPaid: false,
        isExempt: false,
        filterEndedCourses: true,
      })
        .where('userId', this.$auth.user.id)
        .first();
    },
    unpaidDueDate() {
      if (this.unpaidPaymentRequest) {
        const first = this.unpaidPaymentRequest;

        return moment(first.effectiveDueDate);
      }
      return null;
    },
    unpaidSoonDueDate() {
      return this.unpaidDueDate ? this.unpaidDueDate.clone().subtract(1, 'week') : null;
    },
    announcement() {
      if (this.unpaidPaymentRequest) {
        return {
          title: 'Free Trial Ending',
          text: '',
        };
      }

      return {
        title: 'Welcome to Stemble!',
        text: `We are pleased to have you on board this semester. If you have any feedback or
          experience any issues, don't hesitate to contact us at support@stemble.com.`,
      };
    },
    hasSessionId() {
      return !!this.$route.query.session_id;
    },

    coursesAuthorizedForCreateAssignment() {
      return this.courses
        .filter((c) => this.$gate.can('createIn', 'Assignment', c.id))
        .sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },

  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);
  },

  created() {
    // Because this can be a massive amount of data for admins in many courses,
    // we page the data to ensure we stay below our load balancer's 1MB response limit
    const paginator = new Paginator(
      async (pagination) => {
        const courseLikeAssignments = await getCourseLikeAssignments({
          user: this.$auth.user.id,
          includePolicies: true,
          // We subtract 7 days as a hacky way to try and make sure that assignments with extensions
          // are included. Not the end of the world if they're missed since there's the course pages.
          endingAfter: moment().subtract(7, 'days').toISOString(),
          ...pagination,
        });
        await CourseLikeAssignment.insert({data: courseLikeAssignments.data.data});

        return courseLikeAssignments;
      },
      {pageSize: 50}
    );

    this.$loadingFlags
      .loadingHandler(LoadingFlag.DashboardAssignments, paginator.stream())
      .then((entities) => {
        if (!this.$gate.isFaculty()) {
          const courseLikeAssignments = entities[CourseLikeAssignment.entity] || [];
          return this.$loadingFlags.loadingHandler(
            LoadingFlag.DashboardGrades,
            AssignmentGrade.api.fetch({
              user: this.$auth.user.id,
              courseLikeAssignment: courseLikeAssignments.map((cla) => cla.id),
            })
          );
        }
      })
      .catch(this.$errorReporting.errorDialogHandler);

    this.$loadingFlags
      .loadingHandler(
        LoadingFlag.AssignmentExtensions,
        AssignmentExtension.api.fetch({
          course: this.courseId,
          user: this.$auth.user.id,
          selectAllForCourses: true,
        })
      )
      .catch(this.$errorReporting.errorDialogHandler);

    this.initialize();
  },
  methods: {
    checkForExtensionsByAssignment(assignment) {
      return AssignmentExtension.queryByAssignmentUser(assignment.id, this.$auth.user.id).get();
    },
    initialize() {
      //   this.seriesRadarChart = radarChrtSeriesData
      //   this.optionsRadarChart = {
      //     chart: {
      //       toolbar: {
      //         show: false
      //       },
      //       type: 'radar',
      //       dropShadow: {
      //         enabled: false,
      //       },
      //     },
      //     legend: {
      //       show: true,
      //       showForSingleSeries: false,
      //       showForNullSeries: true,
      //       showForZeroSeries: true,
      //       position: 'right',
      //       horizontalAlign: 'center',
      //       fontSize: '11px',
      //       markers: {
      //         width: 24,
      //         height: 24,
      //         strokeWidth: 0,
      //         strokeColor: '#fff',
      //         fillColors: undefined,
      //         radius: 12,
      //         customHTML: undefined,
      //         onClick: undefined,
      //         offsetX: 0,
      //         offsetY: '7px'
      //       },
      //     },
      //     colors: radarChrtColors,
      //
      //     stroke: {
      //       width: 0
      //     },
      //     fill: {
      //       opacity: 1
      //     },
      //     markers: {
      //       size: 2
      //     },
      //     yaxis: {
      //       show: false,
      //     },
      //     xaxis: {
      //       categories: radarChrtXCategories
      //     },
      //
      //     plotOptions: {
      //       radar: {
      //         polygons: {
      //           width: 0,
      //           strokeColors: ['transparent', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
      //           connectorColors: '#ffffff',
      //           fill: {
      //             colors: ['#f2f2f2', '#f2f2f2']
      //           }
      //         }
      //       }
      //     },
      //
      //   }
      //     this.seriesPieChart = pieChrtSeriesData
      //     this.optionsPieChart = {
      //       chart: {
      //         type: 'donut',
      //       },
      //       stroke: {
      //         show: true,
      //         curve: 'smooth',
      //         lineCap: 'butt',
      //         colors: ['#ffffff'],
      //         width: 10,
      //         dashArray: 0,
      //       },
      //       colors: ['#FFD54F', '#FFAB40', '#CB527D', '#FF825B'],
      //       plotOptions: {
      //         pie: {
      //           startAngle: 0,
      //           expandOnClick: true,
      //           offsetX: 0,
      //           offsetY: 0,
      //           customScale: 1,
      //           dataLabels: {
      //             offset: 0,
      //             minAngleToShowLabel: 30,
      //           },
      //           donut: {
      //             size: '70%',
      //             background: 'transparent',
      //             labels: {
      //               show: true,
      //               name: {
      //                 show: true,
      //                 fontSize: '13px',
      //                 fontWeight: 300,
      //                 color: '#4d4d4d',
      //                 offsetY: 0,
      //                 formatter: function(val) {
      //                   return val
      //                 }
      //               },
      //               value: {
      //                 show: true,
      //                 fontSize: '28px',
      //                 fontWeight: 600,
      //                 color: '#4d4d4d',
      //                 offsetY: 10,
      //                 formatter: function(val) {
      //                   return val
      //                 }
      //               },
      //               total: {
      //                 show: true,
      //                 showAlways: true,
      //                 label: 'Total',
      //                 fontSize: '15px',
      //                 fontWeight: 400,
      //                 color: '#4d4d4d',
      //                 formatter: function(w) {
      //                   return w.globals.seriesTotals.reduce((a, b) => {
      //                     return a + b
      //                   }, 0)
      //                 }
      //               }
      //             }
      //           },
      //         }
      //       },
      //       dataLabels: {
      //         dropShadow: {
      //           enabled: false
      //         },
      //         enabled: false
      //       },
      //       legend: {
      //         show: true,
      //         position: 'bottom',
      //         horizontalAlign: 'center',
      //         fontSize: '16px',
      //         fontWeight: 300,
      //         markers: {
      //           width: 24,
      //           height: 24,
      //           strokeWidth: 0,
      //           strokeColor: '#fff',
      //           fillColors: undefined,
      //           radius: 12,
      //           customHTML: undefined,
      //           onClick: undefined,
      //           offsetX: 0,
      //           offsetY: 0
      //         },
      //         labels: {
      //           colors: '#212121',
      //           useSeriesColors: false
      //         },
      //       },
      //       labels: pieChrtLable,
      //     }
    },
  },
};

// const radarChrtSeriesData = [
//   {
//     name: 'Section 1',
//     data: [70, 90, 100, 70, 90, 100, 90, 110],
//   },
//   {
//     name: 'Section 2',
//     data: [50, 70, 70, 40, 70, 65, 80, 80],
//   },
//   {
//     name: 'Section 3',
//     data: [30, 40, 40, 20, 40, 60, 25, 70],
//   }
// ]
//
// const radarChrtXCategories = ['Subject A', 'Subject B', 'Subject C', 'Subject D', 'Subject E', 'Subject F', 'Subject G', 'Subject H']
// const radarChrtColors = ['#FF5722', '#FC9512', '#FFD54F']
// const pieChrtSeriesData = [10, 25, 35, 30]
// const pieChrtLable = ['To do', 'In progress', 'Expired', 'Done']
</script>

<style lang="scss">
.header-section {
  h1 {
    font-size: 25px;
  }

  div {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.v-card__text {
  line-height: 28px;
}

.no-background-hover::before {
  background-color: transparent !important;
}

.v-timeline::before {
  display: none !important;
}

.v-timeline--dense .v-timeline-item__body {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
}

.v-timeline-item__divider {
  display: none !important;
}

.theme--dark .background-row-color {
  background-color: black !important;
}

.theme--light .background-row-color {
  background-color: white !important;
}

@media only screen and (max-width: 768px) {
  .dashboard_card_height {
    height: 90%;
    min-height: 350px;
  }
}

@media only screen and (min-width: 768px) {
  .dashboard_card_height {
    height: 90%;
    min-height: 350px;
  }
}
</style>
