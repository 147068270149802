















import {computed, defineComponent, PropType} from '@vue/composition-api';

type Direction = 'vertical' | 'horizontal';
type Align = 'left' | 'center' | 'right' | 'space-between' | 'space-around';
type Valign = 'top' | 'center' | 'bottom';

export default defineComponent({
  name: 'SStack',
  props: {
    as: {
      type: String,
      default: 'div',
    },
    direction: {
      type: String as PropType<Direction>,
      default: 'vertical',
    },
    gap: {
      type: [String, Number],
      default: '',
    },
    align: {
      type: String as PropType<Align>,
      default: '',
    },
    valign: {
      type: String as PropType<Valign>,
      default: '',
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const directionClass = computed(() => getDirectionClass(props.direction));
    const gapValue = computed(() => getGapValue(props.gap));
    const alignClass = computed(() => getAlignClass(props.direction, props.align));
    const valignClass = computed(() => getValignClass(props.direction, props.valign));
    const wrapClass = computed(() => (props.wrap ? 'flex-wrap' : ''));

    return {
      alignClass,
      valignClass,
      wrapClass,
      directionClass,
      gapValue,
    };
  },
});

function getDirectionClass(direction: Direction) {
  if (direction === 'horizontal') {
    return 'flex-row';
  }
  return 'flex-column';
}

function getAlignClass(direction: Direction, align: string) {
  const attr = {horizontal: 'justify', vertical: 'align'}[direction];
  const value = {left: 'start', right: 'end'}[align] || align;

  return `${attr}-${value}`;
}

function getValignClass(direction: Direction, valign: string) {
  const attr = {horizontal: 'align', vertical: 'justify'}[direction];
  const value = {top: 'start', bottom: 'end'}[valign] || valign;

  return `${attr}-${value}`;
}

function getGapValue(gap: any) {
  if (isNaN(gap)) {
    return gap;
  }
  return `${gap * 4}px`;
}
