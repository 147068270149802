
































import Vue, {PropOptions} from 'vue';
// @ts-ignore: cannot find declaration for JS file
import {USER_PROFILE} from '@/router/route-names';
import {Location} from 'vue-router';

export default Vue.extend({
  name: 'AnnouncementCard',
  props: {
    title: {
      type: String,
      required: true,
    } as PropOptions<string>,
    subTitle: {
      type: String,
      default: null,
    } as PropOptions<string>,
    text: {
      type: String,
      required: true,
    } as PropOptions<string>,
    courseName: {
      type: String,
      default: null,
    } as PropOptions<string>,
    expiryDate: {
      type: String,
      default: null,
    } as PropOptions<string>,
  },
  computed: {
    myProfileTo(): Location {
      return {name: USER_PROFILE};
    },
  },
});
