<template>
  <div id="chart">
    <v-row align="center" justify="start" no-gutters>
      <v-col cols="12">
        <v-menu v-model="menu" offset-x>
          <template v-slot:activator="{attrs, on}">
            <v-btn
              class="font-weight-medium no-background-hover px-0 mx-0"
              v-bind="attrs"
              text
              small
              depressed
              v-on="on"
            >
              <v-icon class="mt-2">
                {{ menu ? 'mdi-menu-up' : 'mdi-menu-down' }}
              </v-icon>
              <p class="mt-6">
                {{ $t('course') }}
              </p>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in itemSections" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <apexchart type="radar" :options="options" :series="series" height="auto" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'RadarChart',
  components: {
    apexchart: VueApexCharts,
  },

  props: {
    options: undefined,
    series: undefined,
    chartNum: undefined,
  },

  data: function () {
    return {
      menu: '',
      itemSections: [
        {
          title: 'Course 1',
        },
        {
          title: 'Course 2',
        },
        {
          title: 'Course 3',
        },
      ],
    };
  },
};
</script>

<style lang="sass">
.v-card--material-chart
  p
    color: #999

  .v-card--material__heading
    max-height: 255px
</style>
