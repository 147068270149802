import moment from 'moment';

/**
 * Class that helps analyze assignment policies and present that information
 * in a clearer way. It centralizes the logic for checking policy information
 * and defines an interface for getting policy information.
 */
export class AssignmentPolicyAnalyzer {
  constructor(courseLikeAssignment) {
    this.courseLikeAssignment = courseLikeAssignment;
  }

  get assignmentPolicies() {
    return this.courseLikeAssignment.assignmentPolicies;
  }

  /**
   * Check to see if a grade is visible at a specific time.
   * @param {moment.Moment|null} now
   * @returns {boolean}
   */
  isGradeVisible(now = null) {
    now = moment(now || undefined);

    if (this.courseLikeAssignment.isQuizMode) {
      const releaseDate = this.courseLikeAssignment.quizModeReleaseDate;
      if (!releaseDate) {
        return false;
      }

      return now.isAfter(moment(releaseDate));
    }

    return true;
  }
}
